import './App.css';
import React from 'react';
import TherapistLoginForm from './TherapistApps/LogIn/TherapistLogin';
import TherapistRegistrationForm from './TherapistApps/Registration/TherapistRegistration';
import PatientRegistrationForm from './PatientApps/PatientRegistration/PatientRegistration';
import PatientLoginForm from './PatientApps/PatientLogin/PatientLogin';
import PatientDashboard from './PatientApps/Account/PatientDashboard';
import AllNavBar from './PatientApps/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SearchPage from './PatientApps/SearchPage/SearchPage';
import FrontPage from './PatientApps/FrontPage/Frontpage';
import AlertDialog from './PatientApps/SearchPage/BookingCompletePage';
import "./index.css";
import BookingComplete from './PatientApps/SearchPage/BookingCompletePage';
import CloudinaryUploadWidget from './TherapistApps/Account/TherapistVideoUpload';
import VideoChatPage from './TherapistApps/VideoChat/VideoPageApp';
//import Reservation from './TherapistApps/VideoChat/CalendarRequest/CalendarRequest';
import WeekView from './TherapistApps/TherapistDashboard/TherapistScheduler/WeekView';
import Therapist_ClientList from './TherapistApps/TherapistDashboard/ClientList/ClientList';
import TherapistDashboard from './TherapistApps/TherapistDashboard/TherapistDashboard';
import ReviewCard from './PatientApps/Reviews/ReviewsCards';
import ReviewCarousel from './PatientApps/Reviews/ReviewsCards';
import LineCallback from './TherapistApps/Registration/LineCallback';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import PatientVideoPage from './PatientApps/Account/PatientVideo/PatientVideoPage';
import Sender from './TherapistApps/VideoChat/CalendarRequest/RequestSender';
import { Send } from '@mui/icons-material';
import Receiver from './PatientApps/Account/PatientVideo/CalendarRequestPopup/CalendarReceiver';
import CompanyOverview from './PatientApps/CompanyPage/CompanyPage';
import TermsofUse from './PatientApps/CompanyPage/termsofuse';
import LegalDisclosure from './PatientApps/FrontPage/ServiceAgreement/Serviceagreement';
import { VideoControls } from './TherapistApps/VideoChat/VideoControl/VideoControl';
import ChargeButton from './TherapistApps/TherapistDashboard/History/patientcharge';
import SaveCCInfo from './PatientApps/Account/RegisterCardInfo';
import TherapistPaymentApp from './TherapistApps/TherapistDashboard/Payments/payments';
import BookingsCalendarModal from './PatientApps/SearchPage/CalendarModal';
import VideoChatPagetest from './TherapistApps/VideoChat/videoapptest';
import PatientVideoV2 from './PatientApps/Account/PatientVideo/PatientVideoPageV2';
import StripeChargeButton from './App.test';







function App() {




  return (    
  <div className="App">
   <Router>
    <AllNavBar /> 
    <Routes>
      <Route exact path='/' element=　{ <FrontPage/> } />
      <Route exact path="/searchtherapist" element=　{<SearchPage/>} />
      <Route exact path='/patientlogin' element=　{ <PatientLoginForm/> } />
      <Route exact path='/therapistlogin' element=　{ <TherapistLoginForm/> } />
      <Route exact path='/patientregister' element=　{ <PatientRegistrationForm/> } />
      <Route exact path='/therapistregister' element=　{ <TherapistRegistrationForm/> } />
      <Route exact path='/patientdashboard' element=　{ <PatientDashboard /> } />
      <Route exact path='/bookingcomplete' element=　{ <BookingComplete /> } />
      <Route exact path='/therapist/scheduler' element=　{ <WeekView /> } />
      <Route exact path='/therapist/clientlist' element=　{ <Therapist_ClientList/> } />
      <Route exact path='/therapistdashboard' element=　{ <TherapistDashboard/> } />
      <Route exact path='/reviews' element=　{ <ReviewCarousel/> } />
      <Route path="/therapist/videochat/:session_uuid" element={<VideoChatPage />} />
      <Route path="/therapist/client-list" element={<Therapist_ClientList />} />
      <Route path="/callback" element={<LineCallback/>} />
      <Route path="/patient/videochat" element={<PatientVideoPage />} />
      <Route path="/patient/room/:session_uuid" element={<PatientVideoV2 />} />
      <Route path="/therapistcalendarrequest"  element={<Sender />} />
      <Route path="/patientreceive"   element={<Receiver />} />
      <Route path="/companyinformation"   element={<CompanyOverview />} />
      <Route path="/termsofuse"   element={<TermsofUse/>} />
      <Route path="/disclosure"   element={<LegalDisclosure/>} />
      <Route path="/videocontrol"   element={<VideoControls/>} />
      <Route path = "/chargebutton" element = {<ChargeButton/>} />
      <Route path = "/patientCC" element = {<SaveCCInfo/>} />
      <Route path = "/therapistpayment" element = {<TherapistPaymentApp/>} />
      <Route path = "/calendarmodal" element = {<BookingsCalendarModal/>} />
      <Route path = "/bookingcomplete" element = {<BookingComplete/>} />
      <Route path = "/videotestpage" element = {<VideoChatPagetest/>} />
      <Route path="/videochat" element={<VideoChatPage />} />
      <Route path = "/patientvideoV2" element = {<PatientVideoV2 />} />
      <Route path = "/chargebutton" element = {<StripeChargeButton />} />
      
      
      
    </Routes>
    

    
   </Router> </div>)
   


  ;
}

export default App;

//<Route exact path='/therapist/videochat' element=　{ <Basics /> } />
//<Route exact path='/therapist/reservation' element=　{ <Reservation /> } />
//<Route path = "/calendarmodal" element = {<InVideoCalendarModal/>} />