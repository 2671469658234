import React, { useEffect, useState } from "react";
import axios from "axios";
import { hostConfig } from "../../config.js";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";

const PatientSchedule = () => {
  const [patientSchedules, setPatientSchedules] = useState([]);
  const [pastSessions, setPastSessions] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [exchangeRates, setExchangeRates] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      try {
        const [schedulesResponse, ratesResponse] = await Promise.all([
          axios.get(`${hostConfig.API_BASE}/getpatientschedule`),
          axios.get('https://api.exchangerate-api.com/v4/latest/USD')
        ]);

        console.log("Raw API response:", schedulesResponse.data);

        const now = new Date();
        const sortedSchedules = schedulesResponse.data.sort((a, b) =>
          new Date(a.booked_session_date_timestamp) - new Date(b.booked_session_date_timestamp)
        );

        const upcomingSessions = sortedSchedules.filter(session => 
          new Date(session.booked_session_date_timestamp) > now
        );
        const pastSessions = sortedSchedules.filter(session => 
          new Date(session.booked_session_date_timestamp) <= now
        ).reverse(); // Most recent past sessions first

        console.log("Processed Upcoming Sessions:", upcomingSessions);
        console.log("Processed Past Sessions:", pastSessions);

        setPatientSchedules(upcomingSessions);
        setPastSessions(pastSessions);
        setExchangeRates(ratesResponse.data.rates);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  const handleJoinMeeting = (meeting_tokens, session_uuid) => {
    localStorage.setItem('session_uuid', session_uuid);
    navigate(`/patient/room/${session_uuid}`, {
      state: {
        meeting_token: meeting_tokens,
        session_uuid: session_uuid,
      },
    });
  };

  const columns = [
    { 
      field: 'booked_session_date_timestamp', 
      headerName: 'Date and Time', 
      width: 200, 
      valueGetter: (params) => {
        console.log("Date and Time params:", params);
        return params;
      }
    },
    { 
      field: 'full_name', 
      headerName: 'Therapist Name', 
      width: 200,
      valueGetter: (params) => {
        console.log("Therapist Name params:", params);
        return params;
      }
    },
    { 
      field: 'hourly_rate', 
      headerName: 'Hourly Rate', 
      width: 200, 
      valueGetter: (params) => {
        console.log("Hourly Rate params:", params);
        return params;
      },
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <span>Hourly Rate</span>
          <Select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            size="small"
            style={{ height: '25px' }}
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="JPY">JPY</MenuItem>
            <MenuItem value="AUD">AUD</MenuItem>
            <MenuItem value="SGD">SGD</MenuItem>
          </Select>
        </div>
      ),
    },
  ];

  const upcomingColumns = [
    ...columns,
    {
      field: 'meeting_token',
      headerName: 'Join Session',
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleJoinMeeting(params.row.meeting_token, params.row.session_uuid)}
          disabled={!params.row || !params.row.meeting_token}
        >
          Join
        </Button>
      ),
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        {patientSchedules.length > 0 ? 'Upcoming Sessions' : 'Sessions'}
      </Typography>
      {patientSchedules.length > 0 ? (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={patientSchedules}
            columns={upcomingColumns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => {
              console.log("Upcoming Row data:", row);
              return row.session_uuid || Math.random().toString();
            }}
          />
        </div>
      ) : (
        <div>
          <Typography variant="h6" gutterBottom>No upcoming sessions scheduled.</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/searchtherapist')}
            style={{ marginBottom: '20px' }}
          >
            Find a Therapist
          </Button>
          
          <Typography variant="h5" gutterBottom>Past Sessions</Typography>
          {pastSessions.length > 0 ? (
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={pastSessions}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10, 20]}
                getRowId={(row) => {
                  console.log("Past Row data:", row);
                  return row.session_uuid || Math.random().toString();
                }}
              />
            </div>
          ) : (
            <Typography>No past sessions found.</Typography>
          )}
        </div>
      )}
    </Container>
  );
};

export default PatientSchedule;