import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Translation resources
const resources = {
  en: {
    translation: {
      navbar: {
        Searchtherapist: "Search for Therapists",
        Login: "Patient Log In",
        Menu: "Menu",
        Logout: "Log Out",
        Aboutus: "About Us",
        Therapistlogin: "Therapist Log In",
        TherapistRegistration: "Therapist Registration",
      },
      heropage: {
        herotext1: "Find your best self",
        herotext2: "and best therapist on Ascre",
        button: "Find Therapist"
      },
      MHInfo: {
        title: "Reality of Mental Health",
        card1title: "Only 33% have the population have gotten chcked",
        card2title: "1 in 4 people have mental struggles",
        card3title: "increase in job departure due to mental health issues",
        card3stat: "50%↑"
      },
      Symptoms: {
        Symptomtitle: "Symptoms",
        Anger: "Anger",
        Drinking: "Drinking",
        Loneliness: "Loneliness",
        SelfHarm: "Self Inflicting Wounds",
        Selfish: "Selfishness",
        Overthinking: "Anxious Thinking",
        Loser: "Imposter Syndrom",
        AttentionSeeking: "Attention Seeking Actions",
        OverEating: "Stress Eating",
        Violence: "Violence",
        Cheating: "Cheating",
        Excuses: "Making Excuses"
      },
      Journey: {
        Journeytitle: "The Journey to be Better",
        Journeysubtitle1: "Try out a therapist",
        Journeysubtitle2: "Admit to your faults",
        Journeysubtitle3: "Release your anger and emotions",
        Journeysubtitle4: "Let go of Trauma and increase self worth",
        Journeydescription1: "Even if you don't bleieve in it, try it out once",
        Journeydescription2: "Try not to reject your feelings and admit to them",
        Journeydescription3: "Release all unrelease emotions and be true to wh you are",
        Journeydescription4: "Live a life with less negative thoughts and feelings",
      },
      HowtoUse: {
        Box1: "Find a counserlor by looking at their videos and reviews and book a session!",
        Box2: "Utilize our suite of products to chat with your therapist",
        Box3: "Review your sessions and therapist so you can help your friends get the best therapists！",
        HowtoUseTitle: "How to Use Ascre",
        Button: "Details"
      },
      FindCounselor: {
        title: "Counselors on Ascre"
      },
      CustomerVoices: {
        customer_voice_title: "Customer Voice",
        customervoice1: "I never liked the other platforms lack of transparency.  Ascre allows me to find the best therpaist",
        customervoice2: "I didn't have any interest in therapy but it's made my life much better",
        customervoice3: "Ascre made it easy for me to find the perfect therapist for me",
        customervoice4: "Never realized how much anxiety I was living with.  Ascre has allowed me to let it and it's been great",
        customername1: "Kota Yamazaki",
        customername2: "Alex Perkins",
        customername3: "Landa Wilmar",
        customername4: "Naina Singh",
      },
      TherapistRecruit: {
        title: "Are you a Therapist?",
        button: "Try Ascre",
        subtitle1: "Would You Like to Grow with Ascre?",
        subtitle2: 'in-app Video Chat',
        subtitle3: 'In-App Booking Systems',
        subtitle4: 'Safe and reliable system',
        message1: 'Would you like to make the mental health industry better? Join us at Ascre',
        message2: 'No more opening zoom or bunch of different apps.  One app to rule them all',
        message3: 'Are you having a tough time keeping track of your customers? We can manage that all for you',
        message4: 'We make sure your data and information is safe.  Keep your sessions safe with Ascre',
      },
      Footer: {
        Company: "Company",
        ToS: "Terms of Serice",
        Support: "Support",
        Disclosure: "Disclosure"
      },
      therapistlogin: {
        title: "Therapist Login",
        email: "email",
        password: "password",
        login: "Log In",
        noaccount: "No Acount Yet? Create your account",
        here: "here",
        linelogin: "Login with Line",
        verify:"Submit"

      },
      patientlogin: {
        title: "Patient Login",
        email: "email",
        password: "password",
        login: "Log In",
        noaccount: "No Acount Yet? Create your account",
        here: "here",
        linelogin: "Login with Line"

      },
      therapistregistration: {
        title: "Therapist Registration",
        email: "Email",
        password: "Password",
        firstname: 'First Name',
        lastname: 'Last Name',
        submit: "Submit",
        agree: "I agree my submitted data is collected and stored"

      },
      patientregistration: {
        title: "Register with the best mental health platform",
        email: "Email",
        password: "Password",
        firstname: 'First Name',
        lastname: 'Last Name',
        submit: "Submit",
        agree: "I agree my submitted data is collected and stored"

      },
      googleregistration: {
        submit: "Sign up with Google 🚀 ",
        logout: "Log Out",
        success:"Registration successful!"
      },
      lineregistration:{
        button:"Register with LINE"
      },
      searchpage: {
        bookingbutton: {
          button: "Book with"
        },
        bookingcalendar: {
          title: "Book a Session"
        },
        bookingcompletepage: {
          bookingcomplete: "Booking Complete with ",
          bookingdate: "We'll see you on ",
          return: "Return to Account"
        },
        bookingmodal: {
          button: "Book a session"
        },
        filterbox: {
          rating: "Rating:",
          practice: "Practice",
          price_range: "Price Range",
          price_value1: "$",
          price_value2: "$$",
          price_value3: "$$$",
          price_value4: "$$$$",
        },

        therapistcards: {
          rating: "Avg Rating: ",
          practice: "Practice: ",
          N_of_Customers: "# of Customers: ",
          price_range: "Price Range:"
        },
        therapistvideo: {
          button:"upload",
        }
      },
      therapistdashboard: {
        videoupload: {
          uploadbutton: "Upload",
        },
        calendar: {},
        clientlist: {
          nextreservation: "Next Reservation Date",
          joinbutton: "Join Next Session",
          jointitle:"Next Meeting",
          patientname:"Patient Name",
          numberofreservation:"Total Reservation #",
          firstsessiondate:"First Session Date",
          subscription:"Subscription"
        },
        commentsreview: {
          submitbutton: "Submit Reply",
          reviewfrom: "Review from",
          generalcomment: "Your reply to their comments",
          replytofeedback: "Your reply to their feedback",
          feedback: "Enter your reply to their feedback",
          warningtitle: "Reply to comments and reviews from:",
          warning1: "Here you can see what your patients have said about you.  You are able to reply here and make your response public.",
          warning2: "Please be honest, concise, and not mean.",
          warning3: "New patients may potentially see your comments."

        },
        scheduler: {
          event: {
            link: "Link",
            savebutton: "Save Event",
            cancel: "Cancel",
            comments: "Comments",
            endtime: "End Time",
            starttime: "Start Time",
            eventdate: "Event Date",
            eventtitle: "Event Title",
            loadingpatient: "Loading Patients..",
            patients: "Patients",
            online:"online",
            inperson:"In-person",
            timezone: "Timezone"

          },
        },
        accountinformation: {
          title: "Account Information",
          name: "First Name: ",
          lastname: "Last Name: ",
          email: "Email: ",
          phonenumber:"phone Number: ",
          price: "Hourly Rate: ",
          description:"Description: ",
          practice:"Practice: ",
          warning:"Account will not be displayed to customers until price and description are entered",
          editbutton:"Edit"
        },
        accountedit: {
          title: "Edit Account Information",
          firstname: "Name: ",
          lastname: "Last Name: ",
          email: "Email: ",
          phonenumber:"phone Number: ",
          price: "Hourly Rate: ",
          description:"Description: ",
          practice:"Practice: ",
          editbutton:"Edit",
          selectcurrency:"Currency"

        },
        history:{
          customername:"Name",
          date:"Date",
          chargebutton:"Charge",
          rate:"Rate",
          paid:"Paid?",
          duration:"Session Length",
          rating:"Review",

        },
        payments:{
          createTitle:"You need to create a Stripe account to manage payments.",
          createButton:"Create Stripe Account",
          createBankTitle:"You cannot withdraw payments until a bank account is connected.",
          createBankButton: "Connect Bank Account",
          paymentsError:"An error occurred while checking your account status. Please try again later.",
          payoutTitle:"Request Payout",
          maxWithdrawalAmount: "Maximum Withdrawal Amount: ",
          amount:"Amount",
          payoutButton:"Request Payout",
          transactionTitle:"Transaction Title",
          transactionAmount: "Amount",
          transactionType:"Type",
          transactionDate:"Date",
          transactionDescription:"Description",
          transactionDownload:"Download CSV"


        },
        
        accounttab: "Account Information",
        schedulertab: "Scheduler",
        calendartab: "Calendar",
        clientlisttab: "Clientlist",
        repliestab: "Reply to Reviews",
        profilevideotab: "Profile Video",
        historytab: "History",
        paymentstab: "Payments",
        subtitle: "Edit Account Information"

      },
      therapistvideochat: {
        calendarrequest: {
          title: "Reservation",
          buttonsentence1: "Reserve on",
          buttonsentence2: "Reserve this Therapist"
        },
        calendarsendbutton: {
          button: "Send Schedule Request"
        },
        notes: {
          placeholder: "Type your notes here..."
        },
        timer: {
          minute: "m",
          seconds: "s",
          resetbutton: "Reset",
          startbutton: "Start",
          pause: "Pause"
        },
        controller: {
          joinbutton: "Join Call",
          scheduler: "you can open your scheduler to schedule your next meeting",
        },
        loading: "Loading....",
        sessiontimer:"Session Timer",
        notestitle:"Notes"
      },
      patientvideo:{
        calendarmodal:{
          title:"Reservation",
          button:"Reserve on:",
          buttonother:"Book another session",
        },
      },
      patientdashboard: {
        accountdelete: {
          button: "Delete Account",
          dialogtitle: "Are you sure?",
          dialog: "Deleting your account is irreversible. Do you want to proceed?",
          dialogbutton: "Cancel",
          dialogbuttondelete: "Delete"
        },
        accountinfo: {
          title: "Account Information",
          firstname: "First Name",
          lastname: "Last Name",
          email: "Email",
          phonenumber: "Phone number",
          edit: "Edit",
        },
        accountedit: {
          title: "Account Information",
          firstname: "First Name",
          lastname: "Last Name",
          Email: "Email",
          phonenumber: "Phone number",
          edit: "Save Changes",
        },
        tabs: {
          info: "Account Information",
          schedule: "Schedule",
          reviews: "Session Reviews",
          delete: "Delete Account",
          payment:"Payments"
        },
        schedule: {
          nextdate: "Next Session Date",
          name: "Therapist",
          rate: "Hourly Rate",
          link: "Meeting",
          join: "Join Meeting",
          loading: "Meeting room not ready yet",
          title: "Session Schedule",
          emptytitle: "Book a session with a therapist!",
          emptybutton: "Book a therapist"
        },
        review: {
          title: "First Impression Review for",
          date: "on",
          description1: "This is a first impression review. Please be honest but also be polite.",
          description2: "People and your therapist will see the reviews you wrote. The ratings will not be shown to the therapist.",
          comfort: "Comfort Level",
          helpfulness: "Helpfulness",
          didyouget: "Did you get what you were looking for from the session/therapist?",
          leavereview: "Please leave a general comment for any other feedback!",
          submit: "Submit Review",
          therapistreview: "Therapist Review of",
          therapistdescription1: "This is a review / feedback for your therpiast that you have decided to see over 5 times. ",
          therapistdescription2: "Please be honest, concise, and not mean. ",
          therapistdescription3: "People and your therapist will see the reviews you wrote. The ratings will not be shown to the therapist.",
          therapistdescription4: "The therapist may respond to your comments.",
          threrapistcomfort: "Comfort Level",
          threrapisthelpful: "Helpfulness",
          threrapistrepeat: "What made you want to repeatedly use this therapist?",
          threrapistgeneral: "Please leave a general comment for any other feedback!",
          impressionscale1: "Very Difficult to Open Up",
          impressionscale2: "Somewhat Difficult to Open Up",
          impressionscale3: "Neutral",
          impressionscale4: "Somewhat Easy to Trust and Open Up",
          impressionscale5: "Very Easy to Trust and Open Up",
          helpfulnessscale1: "Still unsure of what is wrong",
          helpfulnessscale2: "Slightly unsure of what is wrong",
          helpfulnessscale3: "Neutral",
          helpfulnessscale4: "Got some clarity into what I have to work on",
          helpfulnessscale5: "Got a lot of clarity into what I have to work on",


        }


      },
      companypage: {
        title: "Company Summary",
        info: "Company Info",
        company: "Name",
        companycontent: "ascre Inc.",
        founding: "Founded",
        foundingcontent: "2023/4/27",
        money: "Starting capital",
        moneycontent: "210万",
        staff: "Staff",
        staffcontent: "2",
        address: "location",
        addresscontent: "Tokyo",
      },
      termsofuse: {
        title: "Terms of Use",
        firstnumber:'Article 1',
        firstrule:'Purpose',
        firsttext:'These Terms of Use (the "Terms") are intended to establish the terms and conditions for the use of the counseling services (the "Services") provided by Ascre Co., Ltd. (the "Company").',
        secondnumber:'Article 2',
        secondrule:'Fees',
        secondtext:'These Terms apply to all counselors (the "Users") who use the Services.',
        thirdnumber:'Article 3',
        thirdrule:'Scope of Fees',
        thirdtext:'Users shall pay the fees specified by the Company for the use of the Services. The fees sh',
        fourthnumber:'Article 4',
        fourthrule:'Method of Use',
        fourthtext:`
Users shall comply with the Company's specified method of use when using the Services. Users shall comply with the following when using the Services:
  - Not engage in any act that violates laws and public morals.
  - Not engage in any act that infringes the rights of others.
  - Not engage in any act that causes damage to the Company or third parties.
  - Not provide false or misleading information.
  - Not engage in any other act that the Company deems inappropriate.

The Company may issue a warning to the User or suspend the User's use of the Services if the User violates the foregoing.
`,
        fifthnumber:'Article 5',
        fifthrule:'Personal Informafion',
        fifthtext:`The Company shall comply with the Act on the Protection of Personal Information and the Company's privacy policy when handling the User's personal information. Users agree to provide their personal information and the content of their consultations to third parties when using the Services.`,
        sixthnumber:'Article 6',
        sixthrule:'Liability',
        sixthtext:'The Company shall exercise due care in providing the Services. The Company shall not be liable for any damages suffered by the User as a result of the use of the Services, except in cases of intentional or gross negligence.',
        seventhnumber:'Article 7',
        seventhrule:'Scope of Application',
        seventhtext:`The Company may modify these Terms. Any modification of these Terms shall be effective by posting it on the Company's website.`,
        eighthnumber:'Article 8',
        eighthrule:'Governing Law and Jurisdiction',
        eighthtext:'These Terms shall be governed by Japanese law. All disputes arising out of or relating to these Terms shall be finally settled by the Tokyo District Court.',
      },


      "test": "test",
      // other keys and translations...
    }
  },
  ja: {
    translation: {
      navbar: {
        Searchtherapist: "カウンセラーを探す",
        Login: "ログイン",
        Menu: "メニュー",
        Logout: "ログアウト",
        Aboutus: "会社概要",
        Therapistlogin: "カウンセラーログイン",
        TherapistRegistration: "カウンセラー登録",

      },
      heropage: {
        herotext1: "自分の最高の状態を",
        herotext2: "常にキープするためのAscre",
        button: "カウンセラーを探す→"
      },
      MHInfo: {
        title: "メンタルヘルスの現状",
        card1title: "受診している人はとても少なく人口の約",
        card2title: "4人に1人は精神疾患を抱えている",
        card3title: "メンタルヘルスによる過去１０年の離職率の増加",
        card3stat: "50%↑"

      },
      Symptoms: {
        Symptomtitle: "症状",
        Anger: "怒り・苛立ち",
        Drinking: "飲酒",
        Loneliness: "寂しさ・孤独",
        SelfHarm: "蔑む・嘲る",
        Selfish: "自己中心的",
        Overthinking: "考えすぎ",
        Loser: "劣等感",
        AttentionSeeking: "自己顕示欲",
        OverEating: "過食",
        Violence: "暴力",
        Cheating: "浮気・不倫",
        Excuses: "逃げ癖"
      },
      Journey: {
        Journeytitle: "症状改善までの道のり",
        Journeysubtitle1: "カウンセリングを受けてみる",
        Journeysubtitle2: "自分が症状があることを認める",
        Journeysubtitle3: "過去の感情などの発散",
        Journeysubtitle4: "症状の克服、自己肯定感アップ",
        Journeydescription1: "わからなくても一度打ち明けてみることが大事です",
        Journeydescription2: "否定的にならず自分の感情や気持ちを正直に表す",
        Journeydescription3: "感情を発散し本来の自分を感じ取り正直さをとりもします",
        Journeydescription4: "克服すると自己肯定感やネガティブな発想が発散されます",
      },
      HowtoUse: {
        Box1: "アプリ内でカウンセラーを探して、他のお客様のレビューを閲覧して予約",
        Box2: "アプリ内でカウンセリングを行いビデオチャットで自分の悩みを打ち明ける",
        Box3: "経験を記録し自身の症状を和らげ別のお客様にも貢献できるようレビューを記載！",
        HowtoUseTitle: "Ascreの使い方",
        Button: "詳細"
      },
      FindCounselor: {
        title: "Ascreのカウンセラー"
      },
      CustomerVoices: {
        customer_voice_title: "お客様のお声",
        customervoice1: "今までどのようなカウンセラーを見つければいいかわからなくて大変だったけどAscreでいい人を見つけれて嬉しかった",
        customervoice2: "カウンセリングって自分には関係ないものと思っていたけどカウンセリングを受け始めて日常的に満足度が増したよ。",
        customervoice3: "今までどのようなカウンセラーを見つければいいかわからなくて大変だったけどAscreでいい人を見つけれて嬉しかった",
        customervoice4: "日常的にこんなに心が蝕まれてたなんて気づかなかった。Ascreのおかげで",
        customername1: "山崎幸太",
        customername2: "石田あゆみ",
        customername3: "福田圭介",
        customername4: "石井芳正",
      },
      TherapistRecruit: {
        title: "カウンセラー・セラピストですか？",
        button: "Ascreを使ってみる",
        subtitle1: "アスクレと一緒に成長しませんか",
        subtitle2: 'アプリ内ビデオチャット',
        subtitle3: 'アプリ内スケジュール・ブッキング管理',
        subtitle4: '安全で信頼できるシステム',
        message1: '日本のメンタルヘルスを変えていきませんか？アスクレの魅力をご覧ください。',
        message2: 'わざわざズームを開いたり消したりする必要はありません。Ascre内で全部解決可能！',
        message3: 'カウンセリングや顧客管理に困っていませんか？Ascreではそれを簡単に解決できます。',
        message4: '個人情報や管理情報はすべて保護されています。安心仕事に専念してください。',

      },
      Footer: {
        Company: "会社概要",
        ToS: "利用規約",
        Support: "お問い合わせ",
        Disclosure: "特定商取引法に基づく表記"
      },
      therapistlogin: {
        title: "カウンセラーログイン",
        email: "email",
        password: "パスワード",
        login: "ログイン",
        noaccount: "アカウントがない方は",
        here: "こちらから登録",
        linelogin: "ラインでログイン",
        verify:"承認"

      },
      patientlogin: {
        title: "ログイン",
        email: "email",
        password: "パスワード",
        login: "ログイン",
        noaccount: "アカウントがない方は",
        here: "こちらから登録",
        linelogin: "ラインでログイン"

      },
      patientregistration: {
        title: "アスクレに登録",
        email: "Email",
        password: "パスワード",
        firstname: '名',
        lastname: '姓',
        submit: "登録",
        agree: "個人情報の取り扱いに同意する"

      },
      therapistregistration: {
        title: "アスクレに登録",
        email: "Email",
        password: "パスワード",
        firstname: '名',
        lastname: '姓',
        submit: "登録",
        agree: "個人情報の取り扱いに同意する"

      },
      googleregistration: {
        submit: "Googleで登録 🚀 ",
        logout: "ログアウト",
        success:"登録完了"
      },
      lineregistration:{
        button:"LINEで登録"
      },
      searchpage: {
        bookingbutton: {
          button: "予約する"
        },
        bookingcalendar: {
          title: "今すぐ予約する"
        },
        bookingcompletepage: {
          bookingcomplete: "予約が完了しました。カウンセラー：",
          bookingdate: "予約日：",
          return: "アカウントに戻る"
        },
        bookingmodal: {
          button: "予約する"
        },
        filterbox: {
          rating: "評価",
          practice: "タイプ",
          price_range: "値段",
          price_value1: "¥",
          price_value2: "¥¥",
          price_value3: "¥¥¥",
          price_value4: "¥¥¥¥",
        },
        searchpage: {},
        therapistcards: {
          rating: "平均評価: ",
          practice: "タイプ: ",
          N_of_Customers: "顧客数: ",
          price_range: "価格:"
        },
        therapistvideo: {
          
        }
      },
      therapistdashboard: {
        videoupload: {
          uploadbutton: "アップロード",
        },
        calendar: {},
        clientlist: {
          nextreservation: "次回の予約",
          jointitle:"ミーティングボタン",
          joinbutton: "チャットに参加",
          patientname:"顧客名",
          numberofreservation:"予約総数",
          firstsessiondate:"初回日",
          subscription:"定期購買設定"
        },
        commentsreview: {
          submitbutton: "返答を投稿",
          reviewfrom: "Review from",
          generalcomment: "ここに自分の返答を記入",
          replytofeedback: "ここに自分の返答を記入",
          feedback: "ここに自分の返答を記入",
          warningtitle: "Reply to comments and reviews from:",
          warning1: "Here you can see what your patients have said about you.  You are able to reply here and make your response public.",
          warning2: "Please be honest, concise, and not mean.",
          warning3: "New patients may potentially see your comments."

        },
        scheduler: {
          event: {
            link: "リンク",
            savebutton: "保存",
            cancel: "キャンセル",
            comments: "コメント",
            endtime: "終了時間",
            starttime: "開始時間",
            eventdate: "イベントの日程",
            eventtitle: "タイトル",
            loadingpatient: "検索中。。。",
            patients: "顧客リスト",
            online:"オンライン面談",
            inperson:"対面",
            timezone: "タイムゾーン"
          },
        },
        accountinformation: {
          title: "アカウント情報",
          name: "名: ",
          lastname: "姓: ",
          email: "Email: ",
          phonenumber:"電話番号： ",
          price: "価格: ",
          description:"自己紹介: ",
          practice:"主な処方: ",
          warning:"1時間あたりの価格と自己紹介を記入するまでアカウントはお客様に反映されません",
          editbutton:"編集"

        }, accountedit: {
          title: "アカウント情報",
          firstname: "名: ",
          lastname: "姓: ",
          email: "Email: ",
          phonenumber:"電話番号： ",
          price: "価格: ",
          description:"紹介:",
          practice:"主な処方:",
          editbutton:"編集",
          selectcurrency:"通貨"

        },
        history:{
          customername:"顧客名",
          date:"日時",
          chargebutton:"請求",
          amountcharged:"価格",
          paid:"支払い済み",
          duration:"長さ",
          rating:"評価",

        },
        accounttab: "アカウント情報",
        schedulertab: "スケジュール管理",
        calendartab: "カレンダー",
        clientlisttab: "顧客リスト",
        repliestab: "レビューに返答",
        profilevideotab: "自己紹介ビデオ",
        historytab:"履歴",
        paymentstab:"お支払い",
        subtitle: "アカウント情報添削"

      },
      therapistvideochat: {
        calendarrequest: {
          title: "予約",
          buttonsentence1: "予約日：",
          buttonsentence2: "今すぐ予約"
        },
        calendarsendbutton: {
          button: "次回の予約をお願いする"
        },
        notes: {
          placeholder: "ここにメモを記入"
        },
        timer: {
          minute: "分",
          seconds: "秒",
          resetbutton: "リセット",
          startbutton: "開始",
          pause: "一時停止"
        },
        controller: {
          joinbutton: "参加",
          scheduler:"you can open your scheduler to schedule your next meeting",
        },
        loading: "少々お待ちください。。",
        sessiontimer:"タイマー",
        notestitle:"メモ"
      },
      patientvideo:{
        calendarmodal:{
          title:"予約",
          button:"この日程で予約する：",
          buttonother:"カウンセリングを申込む",
        },
      },
      patientdashboard: {
        accountdelete: {
          button: "アカウント削除",
          dialogtitle: "アカウントを削除してもよろしいでしょうか？",
          dialog: "アカウントを一度消すと履歴も全て消えます。よろしいでしょうか？",
          dialogbutton: "キャンセル",
          dialogbuttondelete: "削除"
        },
        accountinfo: {
          title: "アカウント情報",
          firstname: "名前",
          lastname: "姓",
          email: "Email",
          phonenumber: "電話番号",
         
          edit: "情報変更",
        },
        accountedit: {
          title: "アカウント情報",
          firstname: "名前",
          lastname: "姓",
          email: "Email",
          phonenumber: "電話番号",
          edit: "アカウント更新",
        },
        tabs: {
          info: "アカウント情報",
          schedule: "スケジュール",
          reviews: "口コミ",
          delete: "アカウント削除",
          payment:"お支払い情報"
        },
        schedule: {
          nextdate: "次のカウンセリング日時",
          name: "カウンセラー",
          rate: "価格",
          link: "カウンセリング部屋",
          join: "参加する",
          loading: "カウンセリング部屋の準備が整っておりません。",
          title: "スケジュール",
          emptytitle: "今からカウンセラーを探そう!",
          emptybutton: "カウンセラー検索ページへ"
        },
        review: {
          title: "カウンセラーの評価をしてください：",
          date: "",
          description1: "第一印象についてコメントしてください。",
          description2: "コメントはカウンセラーやユーザーが閲覧できるのでよく考えて記載してください.",
          comfort: "安心度",
          helpfulness: "役立ち度",
          didyouget: "カウンセリングして求めていた結果につながりましたか？?",
          leavereview: "カウンセラーのコメントを記入してください!",
          submit: "保存",
          therapistreview: "口コミ：",
          therapistdescription1: "５回以上通い続けたカウンセラーの評価とコメントを記入してください。 ",
          therapistdescription2: "正直で明確な表現をしてください。 ",
          therapistdescription3: "評価とコメントはカウンセラーや一般の人が見れるようになっているのでなるべく意地悪なことは記載しないでください。",
          therapistdescription4: "カウンセラーがコメントに応答することも可能です",
          threrapistcomfort: "リラックス度",
          threrapisthelpful: "役立ち度",
          threrapistrepeat: "このカウンセラーをなん度も利用した理由はなんでしょうか？?",
          threrapistgeneral: "全体的なコメントや意見を記載してください!",
          impressionscale1: "心を開くのがとても難しかった",
          impressionscale2: "心を開きたくなかった",
          impressionscale3: "普通",
          impressionscale4: "心は開けたけど全部は開かなかった",
          impressionscale5: "とても安心して心を開けた",
          helpfulnessscale1: "何もわからぬまま終わった",
          helpfulnessscale2: "少しだけわかったけど不満はある",
          helpfulnessscale3: "普通",
          helpfulnessscale4: "明確になったがすっきりはしていない",
          helpfulnessscale5: "問題が明確になり解決方法や療法を教えてくれた",
        }

      },
      companypage: {
        title: "会社概要",
        info: "会社情報",
        company: "社名",
        companycontent: "株式会社ascre",
        founding: "設立",
        foundingcontent: "2023/4/27",
        money: "資本金",
        moneycontent: "210万",
        staff: "従業員数",
        staffcontent: "2",
        address: "所在地",
        addresscontent: "東京都世田谷区三軒茶屋",
      },
      termsofuse: {
        title: "利用規約",
        firstnumber: "第1条",
        firstrule: "目的",
        firsttext: 'この利用規約は、株式会社Ascre（以下「当社」という。）が提供するカウンセリングサービス（以下「本サービス」という。）の利用条件を定めることを目的とする。',
        secondnumber: "第2条",
        secondrule: "利用料金",
        secondtext: '本規約は、本サービスを利用するすべてのカウンセラー（以下「利用者」という。）に適用される。',
        thirdnumber: "第3条",
        thirdrule: "料金適用範囲",
        thirdtext: `利用者は、本サービスの利用にあたり、当社所定の利用料金を支払うものとする。
        利用料金は、当社のウェブサイトに掲載するものとする。
        利用料金は、前払いで支払うものとする。`,
        fourthnumber: "第4条",
        fourthrule: "利用方法",
        fourthtext: `
        利用者は、本サービスを利用するにあたり、当社の定める利用方法に従うものとする。
        
        利用者は、本サービスを利用するにあたり、以下の事項を遵守するものとする。
        
        法令及び公序良俗に反する行為を行わないこと。
        他人の権利を侵害する行為を行わないこと。
        当社または第三者に損害を与える行為を行わないこと。
        虚偽の情報または誤解を与える情報を提供しないこと。
        その他、当社が不適切と判断する行為を行わないこと。
        当社は、利用者が前項に違反した場合、利用者に対して警告を発し、または本サービスの利用を停止することができる。
        `,
        fifthnumber: "第5条",
        fifthrule: "個人情報の取扱い",
        fifthtext: `
        当社は、利用者の個人情報を取り扱うにあたり、個人情報保護法及び当社プライバシーポリシーを遵守するものとする。
        利用者は、本サービスを利用するにあたり、自身の個人情報及び相談内容を第三者に提供することを承諾するものとする。`,
        sixthnumber: "第6条",
        sixthrule: "責任",
        sixthtext: `当社は、本サービスの提供にあたり、細心の注意を払うものとする。
        当社は、本サービスの利用によって生じた利用者の損害について、故意または重過失があった場合を除き、一切の責任を負わないものとする。`,
        seventhnumber: "第7条",
        seventhrule: "適用範囲",
        seventhtext: `当社は、本規約を変更することができる。
        本規約の変更は、当社のウェブサイトに掲載することにより効力を生じるものとする。`,
        eighthnumber: "第8条",
        eighthrule: "準拠法及び管轄裁判所",
        eighthtext: `

        本規約は、日本法に準拠するものとする。
        
        本規約に関する紛争は、東京地方裁判所を第一審の専属管轄裁判所とする。
        
        附則
        
        本規約は、2024年2月6日に施行する。`,
      },


      "test": "テスト"

      // other keys and translations...
      //import { useTranslation } from "react-i18next";
      //  const { t,i18n } = useTranslation();

    }
  }
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next.
  .init({
    resources,
    lng: "ja", // Initial language.

    interpolation: {
      escapeValue: false // React already safes from XSS.
    }
  });

export default i18n;
