import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { hostConfig } from '../../../config';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import ChargeButton from "./patientcharge";
import Typography from '@mui/material/Typography';
import moment from 'moment-timezone';

const History = () => {
  const { t } = useTranslation();
  const [therapists, setTherapists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${hostConfig.API_BASE}/therapisthistory`);
        setTherapists(response.data);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSessionJoin = async (session_uuid) => {
    try {
      const response = await axios.post(`${hostConfig.API_BASE}/therapistjoinroom`, { session_uuid });
      console.log('Session join successful', response.data);
      // Redirect to the session room or handle the response as needed
    } catch (error) {
      console.error('Error joining session:', error);
    }
  };

  const columns = [
    { field: 'patient_full_name', headerName: t('therapistdashboard.history.customername'), width: 200 },
    { 
      field: 'booked_session_date_timestamp', 
      headerName: t('therapistdashboard.history.date'), 
      width: 250,
      renderCell: (params) => {
        const formattedDate = moment(params.value)
          .tz(params.row.preferred_timezone || 'UTC')
          .format('YYYY-MM-DD HH:mm');
        return (
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            {formattedDate}
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => handleSessionJoin(params.row.session_uuid)}
            >
              Join
            </Button>
          </div>
        );
      }
    },
    { 
      field: 'hourly_rate', 
      headerName: t('therapistdashboard.history.hourlyrate'), 
      width: 150,
      renderCell: (params) => `${params.value} ${params.row.currency || 'USD'}`
    },
    {
      field: 'charge',
      headerName: t('therapistdashboard.history.chargebutton'),
      width: 150,
      renderCell: (params) => {
        console.log('Charge cell params:', params.row);
        if (params.row.charged) {
          return (
            <Typography color="textSecondary">
              Charged
            </Typography>
          );
        }
        return (
          <ChargeButton 
            patient_uuid={params.row.patient_uuid}
            hourly_rate={params.row.hourly_rate}
            currency={params.row.currency}
            therapist_uuid={params.row.therapist_uuid}
            patient_full_name={params.row.patient_full_name}
            session_uuid={params.row.session_uuid}
          />
        );
      }
    },
    { field: 'duration_formatted', headerName: t('therapistdashboard.history.duration'), width: 200 },
  ];

  return (
    <div className="therapist-clientlist">
      <div className="data-grid-container" style={{ height: 400, width: 1000 }}>
        <DataGrid
          rows={therapists.map((therapist, index) => ({
            id: index,
            ...therapist,
            charged: Boolean(therapist.charged) // Ensure charged is a boolean
          }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </div>
    </div>
  );
}

export default History;